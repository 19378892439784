/*
 * @Date: 2023-03-22 10:20:51
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 14:00:06
 * @FilePath: \J1-M2-QRP-CODE\pages\_app.js
 */
import App from 'next/app';

import React from 'react';

import { ConfigProvider } from 'antd';

import '$DATA/Less/merge.less';

import Head from 'next/head';



export default class MyApp extends App {

	componentDidMount() {
		// This runs only on the client after the component has mounted
		document.body.style.position = 'relative';

		// Select all input elements and update their font-size to prevent zoom on iPhones
		const inputs = document.querySelectorAll('input, textarea');
		inputs.forEach(input => {
		  input.style.fontSize = '16px';
		});
		
	}
	
	render(){
		const { Component, pageProps } = this.props;
		let pathname = global.location && global.location.pathname.split('/')[1] || 'm2-001';

		return (
			<div>
				<Head>
					<link rel="icon" type="image/png" sizes="32x32" href="/static/images/favicon.ico"></link>
					<title>{pathname && pathname === 'restrict' ? '404 เว็บไซต์อย่างเป็นทางการของ JBO - เดิมพันออนไลน์ กีฬา คาสิโน บาคาร่า สล็อต หวย - เล่นบนมือถือ ปลอดภัย ฝาก-ถอนเร็ว' : 'เว็บไซต์อย่างเป็นทางการของ JBO - เดิมพันออนไลน์ กีฬา คาสิโน บาคาร่า สล็อต หวย - เล่นบนมือถือ ปลอดภัย ฝาก-ถอนเร็ว'}</title>
				</Head>
				<ConfigProvider>
					<Component { ...pageProps } />
				</ConfigProvider>
			</div>
		)
	}
}